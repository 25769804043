@font-face {
  font-family: Redwing-l;
  src: local(Redwing-l), url(./fonts/Redwing-Light.otf) format("truetype");
}

@font-face {
  font-family: Redwing-m;
  src: local(Redwing-m), url(./fonts/Redwing-Medium.otf) format("truetype");
}
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: Redwing-l;
}
body {
  background: #ededed;
  width: 100%;
  /* height: 100vh; */
  /* max-height: 100vh; */
  /* overflow: hidden; */
}

#root {
  width: 100%;
}

@keyframes rotate2d {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(1080deg);
  }
}
@keyframes rotate3d {
  from {
    transform: rotateY(0deg);
  }
  to {
    transform: rotateY(360deg);
  }
}
@keyframes borderAnimation {
  from {
    background-position: 0 0, -20px 0, 100% -20px, 0 100%;
  }
  to {
    background-position: 0 -20px, 0 0, 100% 0, -20px 100%;
  }
}
